import Dayjs from 'dayjs';
import { uniq } from 'lodash';
import { dateFormating } from '@/util';
import { DATE_FORMAT_DEFAULT, PRODUCT, DATE_FORMAT_HOUR_DEFAULT } from "@/util/const"
import ReservationRequestDTO from "@/app/admin/modules/candidates/core/DTO/reservationRequestDTO"
import ReservedExamDTO from "@/app/admin/modules/candidates/core/DTO/reservedExamDTO.js"
import SentEmailModel from "@/app/admin/modules/candidates/core/models/candidatesSentEmailModel"
import { REQUEST_ARGS } from "./candidates/candidatesReservationRequest.new.dto"
import CourseModel from "@/app/admin/models/courseModel"
import { PaymentLog } from "@/models/paymentModel"

const regexp = /^([0-2][0-9]|(3)[0-1])(\-)(((0)[0-9])|((1)[0-2]))(\-)\d{4}$/

export default class CourseDayModel {
  constructor(item = {
    sent_emails: []
  }) {
    this.journey_day = item.journey_day;
    this.ID = item.id;
    this.candidateNumber = item.number;
    this.candidateBirthday = item.date_of_birth ? Dayjs(item.date_of_birth).format(DATE_FORMAT_DEFAULT) : null;
    this.candidateName = item.name;
    this.firstName = item.first_name;
    this.lastName = item.last_name;
    this.accountUsername = item.cbr_user;
    this.status = item.cbr_status;
    this.applicationId = item.application_id;
    this.courseLocation = item.course_location;
    this.course = new CourseModel(item.course || {});
    this.courseDate = item.course_date ? Dayjs(item.course_date).format(DATE_FORMAT_DEFAULT) : null;
    this.lastError = item.last_error;
    this.wasExchanged = item.was_exchanged;
    this.candidateEmail = item.email;
    this.candidateTel = item.phone;
    this.failed = item.failed;
    this.confirmedCourses = item.confirmed_courses ? item.confirmed_courses.map(course => new CourseModel(course)) : [];
    this.productName = item.product_name || PRODUCT.BTH;
    this.language = item.language;
    this.lastError = item.last_error;
    this.exam = new ReservedExamDTO(item.exam);
    this.notes = item.notes;
    this.needProcessing = item.need_processing;
    this.isWaitList = item.is_wait_list;
    this.appPlacedAt = item.application_placed_at;
    this.appPaidAt = item.application_paid_at;
    this.address = item.address;
    this.drivingSchool = item.driving_school;
    this.sentEmails = item.sent_emails ? item.sent_emails.map(email => new SentEmailModel(email)) : [];
    this.payments = item.payments ? item.payments.map(payment => new PaymentLog(payment)) : [];
    this.paidAmount = item.paid_amount;
    this.final_amount = item.final_amount;
    this.refunded_amount = item.refunded_amount;
    this.are_dates_fixed = item.are_dates_fixed;
    this.color = item.color;
    this.course_configuration = item.course_configuration;
    this.exercises_access_expires_at = item.exercises_access_expires_at ? Dayjs(item.exercises_access_expires_at).format(DATE_FORMAT_HOUR_DEFAULT) : null;
    this.unsubscribe_from_suggestions = item.unsubscribe_from_suggestions;
    this.is_course_paid = item.is_course_paid;
    this.recommended_payment = item.recommended_payment;
    this.restore_after_exam = item.restore_after_exam;
    this.exams = item.exams;
    this.needs_approval = item.needs_approval;
    this.has_approval = item.has_approval;
    this.package_name = item.package_name;
    this.has_package = item.has_package;
    this.course_type = item.course_type;
    this.result = item.exam ? {...item.exam} : null;
    this.onHold = item.is_on_hold;
    this.noDates = item.no_dates;
    this.inQueue = item.is_in_queue;
    this.inExchangeQueue = item.is_in_exchange_queue;
    this.noCandidateNumber = item.no_candidate_number;
    this.acceptanceError = item.acceptance_error;
    this.reservationError = item.reservation_error;
    this.exchangeError = item.exchange_error;
    this.exchange_count = item.exchange_count;
    this.newReserve = item.new_reserve;
    this.newExchange = item.new_exchange;
    this.isMijnReservation = item.is_mijn_reservation;
    this.labels = item.labels || [];
    this.willComeToCourse = item.will_come_to_course;
    this.presentie_status = item.presentie_status;
    this.canceled = item.canceled;
    this.wiki_access_days = item.wiki_access_days || 0;
    this.wiki_access_from_date = item.wiki_access_from_date ? Dayjs(item.wiki_access_from_date).format(DATE_FORMAT_DEFAULT) : null;
    this.reservation_days_offset = item.reservation_days_offset;
    this.zoom_status = item.zoom_status;
    this.course_photo = item.course_photo;
    this.course_photo_consent = item.course_photo_consent

    this._apiAttributes = {
      number: 'candidateNumber',
      date_of_birth: 'candidateBirthday',
      cbr_user: 'accountUsername',
      cbr_status: 'status',
      exam: 'exam',
      exam_reservation_requests: 'requests',
      phone: 'candidateTel',
      failed: 'failed',
      was_exchanged: 'wasExchanged',
      notes: 'notes',
      is_wait_list: 'isWaitList',
      last_error: 'lastError',
      is_in_queue: 'inQueue',
      is_in_exchange_queue: 'inExchangeQueue',
      no_dates: 'noDates',
      no_candidate_number: 'noCandidateNumber',
      acceptance_error: 'acceptanceError',
      reservation_error: 'reservationError',
      exchange_error: 'exchangeError',
      exchange_count: 'exchange_count',
      new_reserve: 'newReserve',
      new_exchange: 'newExchange',
      product_name: "productName",
      language: "language",
      labels: 'labels',
      is_mijn_reservation: "isMijnReservation",
      sent_email: 'sentEmails',
      payments: 'payments',
      email: 'candidateEmail',
      course_id: 'courseDate',
      are_dates_fixed: 'are_dates_fixed',
      course_configuration: 'course_configuration',
      exercises_access_expires_at: 'exercises_access_expires_at',
      color: 'color',
      will_come_to_course: 'willComeToCourse',
      unsubscribe_from_suggestions: 'unsubscribe_from_suggestions',
      restore_after_exam: 'restore_after_exam',
      needs_approval: 'needs_approval',
      has_approval: 'has_approval',
      presentie_status: 'presentie_status',
      canceled: 'canceled',
      wiki_access_days: 'wiki_access_days',
      wiki_access_from_date: 'wiki_access_from_date',
      reservation_days_offset: 'reservation_days_offset',
      zoom_status: 'zoom_status',
      course_photo: 'course_photo',
      course_photo_consent: 'course_photo_consent'
    };
    this.animation = false;
    this.editing = false;

    this._apiAttributesNested = {
      requests: {
        exam_date: 'examDate',
        exam_time_from: 'timeFrom',
        exam_time_to: 'timeTo',
        location: 'location',
        comment: 'comment',
        course_id: 'courseDate',
        is_on_hold: 'onHold',
        [REQUEST_ARGS.COMMENT]: 'examComment'
      },
    };

    if (item.exam_request_history) {
      this.exam_request_history = item.exam_request_history.map(exam => new ReservationRequestDTO(exam)) || [];
    }

    if (!item.exam_reservation_requests || !item.exam_reservation_requests.length) {
      this.requests = [new ReservationRequestDTO({added_manually: true})];
    } else {
      this.requests = item.exam_reservation_requests.map(requestItem => new ReservationRequestDTO(requestItem));
      this.requests.forEach(requestItem => requestItem.courseLocation = requestItem.courseLocation ? requestItem.courseLocation : this.courseLocation);
    }
  }

  get filteredRequests() {
    if (this.hasReservedExam) return this.requests.filter(request => request.isExchange);
    else return this.requests.filter(request => !request.isExchange);
  }

  get hasReservedExam() {
    return !!this.exam.location;
  }

  get fullName() {
    return this.candidateName;
  }

  get sortedFilteredRequests() {
    return this.filteredRequests.sort((a, b) => b.slotsCount - a.slotsCount);
  }

  merge(candidate) {
    for (let field in candidate) {
      if (field === 'requests') {
        if (!candidate[field].length) return;
        let requests = candidate[field].map(newRequest => {
          let sameRequest = this.requests.find(oldRequest => newRequest.ID === oldRequest.ID);
          if (!sameRequest) return newRequest;
          return {...newRequest, exam: sameRequest.exam};
        });
        this.requests = requests;
        return;
      }
      this[field] = candidate[field];
    }
  }

  get dateExercisesAccessExpires() {
    if (!this.exercises_access_expires_at) return '';
    const dateString = this.exercises_access_expires_at.substring(0, this.exercises_access_expires_at.lastIndexOf('-') + 5);
    if (!regexp.test(dateString)) return '';
    return this.exercises_access_expires_at ? dateString : '';
  }

  set dateExercisesAccessExpires(date) {
    this.exercises_access_expires_at = `${date}${this.timeExercisesAccessExpires}`;

    if (!date.length) {
      this.timeExercisesAccessExpires = date;
    }
  }

  get timeExercisesAccessExpires() {
    if (!this.exercises_access_expires_at) return '';
    const dateString = this.exercises_access_expires_at.substring(0, this.exercises_access_expires_at.lastIndexOf('-') + 5);
    const timeString = this.exercises_access_expires_at.substring(this.exercises_access_expires_at.lastIndexOf('-') + 5);
    if (!regexp.test(dateString)) return '';
    return this.exercises_access_expires_at ? timeString : '';
  }

  set timeExercisesAccessExpires(time) {
    this.exercises_access_expires_at = `${this.dateExercisesAccessExpires}${time}`;
  }

  addReservationRequest() {
    this.requests.push(new ReservationRequestDTO({
      is_exchange: this.hasReservedExam ? true : false,
      added_manually: true,
    }));
  }

  // for empty requests
  addFilledReservationRequests(requests) {
    if (this.requests.length === 1 && !this.requests[0].course.ID) {
      this.requests = [...requests];
    } else {
      this.requests = uniq([...this.requests, ...requests]);
    }
  }

  addReservationTo(requests) {
    requests.push(new ReservationRequestDTO({
      is_exchange: this.hasReservedExam ? true : false,
      [REQUEST_ARGS.COURSE_LOCATION]: this.courseLocation,
      added_manually: true,
    }));
  }

  removeReservationRequest(index) {
    if (this.filteredRequests.length <= 1) return;
    let deletedIndex;
    this.requests.forEach((request, requestIndex) => {
      if (deletedIndex >= 0) return;
      let same = true;
      for (let field in this.filteredRequests[index]) {
        if (this.filteredRequests[index][field] === request[field]) continue;
        same = false;
      }
      if (same) deletedIndex = requestIndex;
    });
    this.requests.splice(deletedIndex, 1);
  }

  getApiData() {
    let data = {
      number: this.candidateNumber,
      date_of_birth: this._getDateApiData(this.candidateBirthday),
      first_name: this.firstName,
      last_name: this.lastName,
      course_date: this._getDateApiData(this.courseDate),
      name: this.candidateName,
      email: this.candidateEmail,
      cbr_user: this.accountUsername,
      cbr_status: this.cbr_status,
      are_dates_fixed: this.are_dates_fixed,
      course_configuration: this.course_configuration,
      unsubscribe_from_suggestions: this.unsubscribe_from_suggestions,
      color: this.color,
      restore_after_exam: this.restore_after_exam,
      failed: this.failed,
      course_location: this.courseLocation,
      phone: this.candidateTel,
      product_name: this.productName,
      labels: this.labels,
      course_id: this.course.ID,
      exercises_access_expires_at: this._getDateApiData(this.dateExercisesAccessExpires) ? this._toDateObject(`${this._getDateApiData(this.dateExercisesAccessExpires)}${this.timeExercisesAccessExpires}`) : null,
      wiki_access_days: this.wiki_access_days || 0,
      wiki_access_from_date: this._getDateApiData(this.wiki_access_from_date),
      reservation_days_offset: this.reservation_days_offset,
      zoom_status: this.zoom_status
    }
    let requests = this._getRequestsApiData()
    data.exam_reservation_requests = requests
    return data
  }

  _toDateObject(date) {
    if (date && date.trim().length) {
      return Dayjs(date, 'YYYY-MM-DD HH:mm').toISOString();
    }
    return null;
  }

  _getDateApiData(date) {
    let _date = dateFormating(date);
    if (!_date) return null;
    return _date.join('-');
  }

  _getRequestsApiData() {
    return this._getRequestsApiDataBy(this.filteredRequests);
  }

  _getRequestsApiDataBy(payload, isExchange) {
    // Todo need to refactor
    let exchange = isExchange || (this.exam.location ? true : false)
    let requests
    if (!payload || !payload.length || !payload[0].course.ID) return []
    if (exchange && !isExchange) requests = payload.filter(request => request.isExchange)
    else requests = payload.filter(request => !request.isExchange)
    requests = requests.map(request => {
      return {
        location: request.location,
        exam_date: this._getDateApiData(request.examDate),
        exam_time_from: request.timeFrom,
        exam_time_to: request.timeTo,
        course_id: request.course.ID,
        is_on_hold: request.onHold,
        comment: request.comment || '',
        [REQUEST_ARGS.EXAM_COMMENT]: request.examComment || '',
        is_exchange: exchange,
      }
    })
    return requests
  }

  _getRemoveRequestsApiDataBy(payload) {
    if (!payload || !payload.length || !payload[0].course.ID) return [];
    return payload.map(request => {
      return {
        location: request.location,
        exam_date: this._getDateApiData(request.examDate),
        exam_time_from: request.timeFrom,
        exam_time_to: request.timeTo,
        course_id: request.course.ID
      };
    });
  }

  getSpreadsheetData(store) {
    let candidateCBRAccount = store.getters['cbrUser/getNameByID'](this.accountUsername);
    let reservedInfo = this._getExamDateData();
    let planner = this._getPlanner();
    let data = [this.candidateName, this.candidateBirthday, this.candidateNumber, this.candidateEmail, this.candidateTel, reservedInfo, candidateCBRAccount, planner];
    return data.join('\t');
  }

  _getExamDateData() {
    let reservedInfo;
    if (!this.exam || !this.exam.examDate) return;
    let hours = this.exam.examDate.split(' ')[1];
    let productInfo = '';
    if (this.productName === PRODUCT.BTH_VE) productInfo = 'VE';
    else if (this.isMijnReservation) productInfo = 'EE ';
    if (this.productName === PRODUCT.BTH_VE && this.isMijnReservation) productInfo = 'EE+VE';
    reservedInfo = `${hours} - ${this.exam.code} ${productInfo} - ${this.exam.location}`;
    return reservedInfo;
  }

  _getPlanner() {
    if (this.isMijnReservation) return 'MIJNCBR';
    if (this.productName === PRODUCT.BTH) return 'Automatic Tool';
    if (this.productName === PRODUCT.BTH_VE) return 'Extratijd examen';
  }
}
