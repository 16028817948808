import ReservationDTO from "./DTO/reservationDTO"
import ReservationRequestDTO, { REQUEST_ARGS } from "./DTO/reservationRequestDTO"
import Dayjs from "dayjs"
import { PRODUCT } from "@/util/const"
import { convertToDefaultDateHourFormat, dateFormating } from "@/util"
import { DATE_INDIAN as DATE_VALIDATION_RULES } from "@/util/validation-rules"
import { CBR_STATUS } from "./candidates-const"
import uniq from '@/util/uniqInArray.js'

const regexp = /^([0-2][0-9]|(3)[0-1])(\-)(((0)[0-9])|((1)[0-2]))(\-)\d{4}$/

export default class ReservationItemModel {
  constructor(item = {
    sent_emails: []
  }) {
    Object.assign(this, new ReservationDTO(item));
    this._apiAttributes = {
      number: 'candidateNumber',
      date_of_birth: 'candidateBirthday',
      cbr_user: 'accountUsername',
      cbr_status: 'status',
      exam: 'exam',
      exam_reservation_requests: 'requests',
      phone: 'candidateTel',
      failed: 'failed',
      was_exchanged: 'wasExchanged',
      exchange_count: 'exchange_count',
      notes: 'notes',
      is_wait_list: 'isWaitList',
      last_error: 'lastError',
      is_in_queue: 'inQueue',
      is_in_exchange_queue: 'inExchangeQueue',
      no_dates: 'noDates',
      no_candidate_number: 'noCandidateNumber',
      acceptance_error: 'acceptanceError',
      reservation_error: 'reservationError',
      exchange_error: 'exchangeError',
      new_reserve: 'newReserve',
      new_exchange: 'newExchange',
      license_type: "licenseType",
      product_name: "productName",
      language: 'language',
      labels: 'labels',
      is_mijn_reservation: 'isMijnReservation',
      mijn_exam_location: 'mijn_exam_location',
      mijn_exam_datetime: 'mijn_exam_datetime',
      force_reapply_allowed_until: 'force_reapply_allowed_until',
      sent_email: 'sentEmails',
      payments: 'payments',
      email: 'candidateEmail',
      course_id: 'courseDate',
      course_type: 'course_type',
      are_dates_fixed: 'are_dates_fixed',
      exercises_access_expires_at: 'exercises_access_expires_at',
      color: 'color',
      unsubscribe_from_suggestions: 'unsubscribe_from_suggestions',
      restore_after_exam: 'restore_after_exam',
      needs_approval: 'needs_approval',
      has_approval: 'has_approval',
      canceled: 'canceled',
      wiki_access_days: 'wiki_access_days',
      wiki_access_from_date: 'wiki_access_from_date',
      wiki_access_days_left: 'wiki_access_days_left',
      online_access_days_left: 'online_access_days_left',
      reservation_days_offset: 'reservation_days_offset',
      has_package: 'has_package',
      package_info: 'package_info',
      package_name: 'package_name',
      package_starting_at: 'package_starting_at',
      package_ending_at: 'package_ending_at',
      package_paid_amount: 'package_paid_amount',
      package_exams_only_at: 'package_exams_only_at'
    };
    this.animation = false;
    this._apiAttributesNested = {
      requests: {
        exam_date: 'examDate',
        exam_time_from: 'timeFrom',
        exam_time_to: 'timeTo',
        location: 'location',
        comment: 'comment',
        course_id: 'courseDate',
        is_on_hold: 'onHold',
        [REQUEST_ARGS.EXAM_COMMENT]: 'examComment'
      },
    };
  }

  // calculate expired exercises date
  get dateExercisesAccessExpires() {
    if (!this.exercises_access_expires_at) return '';
    const dateString = this.exercises_access_expires_at.substring(0, this.exercises_access_expires_at.lastIndexOf('-') + 5);
    if (!regexp.test(dateString)) return '';
    return this.exercises_access_expires_at ? dateString : '';
  }

  set dateExercisesAccessExpires(date) {
    this.exercises_access_expires_at = `${date}${this.timeExercisesAccessExpires}`;

    if (!date.length) {
      this.timeExercisesAccessExpires = date;
    }
  }

  get timeExercisesAccessExpires() {
    if (!this.exercises_access_expires_at) return '';
    const dateString = this.exercises_access_expires_at.substring(0, this.exercises_access_expires_at.lastIndexOf('-') + 5);
    const timeString = this.exercises_access_expires_at.substring(this.exercises_access_expires_at.lastIndexOf('-') + 5);
    if (!regexp.test(dateString)) return '';
    return this.exercises_access_expires_at ? timeString : '';
  }

  set timeExercisesAccessExpires(time) {
    this.exercises_access_expires_at = `${this.dateExercisesAccessExpires}${time}`;
  }
  //

  get fullName() {
    return this.candidateName;
  }

  get hasReservedExam() {
    return !!this.exam.location;
  }

  get filteredRequests() {
    if (this.hasReservedExam) return this.requests.filter(request => request.isExchange);
    else return this.requests.filter(request => !request.isExchange);
  }

  get sortedFilteredRequests() {
    return this.filteredRequests.sort((a, b) => b.slotsCount - a.slotsCount);
  }

  get disabledMarkAsProcessed() {
    return !this.needProcessing;
  }

  get appPlacedAtFormatted() {
    if (!this.appPlacedAt) return '-';
    return convertToDefaultDateHourFormat(this.appPlacedAt);
  }

  get appPaidAtFormatted() {
    if (!this.appPaidAt) return '-';
    return convertToDefaultDateHourFormat(this.appPaidAt);
  }

  isValid() {
    let valid = true;
    let requiredFields = [
      this.candidateName,
      this.candidateBirthday,
      this.candidateEmail
    ];
    requiredFields.forEach(field => {
      if (!field) valid = false;
    });
    if (!this.candidateNumber && (!this.firstName || !this.lastName)) valid = false;
    return valid;
  }

  get disabledDelete() {
    if (this.status === CBR_STATUS.RESERVED) return true;
    return false;
  }

  initBy(str, Converter) {
    if (!str || str.split(/(\s+)/g).length < 5) return false;
    let data = Converter.getData(str);
    this._setItemFromData(data);
  }

  _setItemFromData(data) {
    if (data.candidateName) this.candidateName = data.candidateName;
    if (data.candidateBirthday) this.candidateBirthday = data.candidateBirthday;
    if (data.candidateNumber) this.candidateNumber = data.candidateNumber;
    if (data.candidateEmail) this.candidateEmail = data.candidateEmail;
    if (data.candidateTel) this.candidateTel = data.candidateTel;
    if (data.accountUsername) this.accountUsername = data.accountUsername;
    if (data.firstName) this.firstName = data.firstName;
    if (data.lastName) this.lastName = data.lastName;
  }

  validate(validator) {
    validator.resetFields();
    this._validateCandidateNumber(validator);
    this._validateCourse(validator);
    this._validateBirthday(validator);
    this._validatePhone(validator);
    this._validateReservation(validator);
    return validator.isValid();
  }

  _validateCandidateNumber(validator) {
    validator.validField();
    if (!this.candidateNumber && (!this.firstName || !this.lastName)) {
      validator.invalidField('candidateNumber', 'Candidate should have number or first and last name');
      validator.invalidField('firstName', 'Candidate should have number or first and last name');
      validator.invalidField('lastName', 'Candidate should have number or first and last name');
    }
    if (this.candidateNumber) {
      if (this.candidateNumber.length >= 8 && this.candidateNumber.length <= 10) return;
      else validator.invalidField('candidateNumber', 'Candidate number must contain between 8 and 10 character');
    }
  }

  _validateCourse(validator) {
    if (!this.course.city) return
    if (!this.course.ID) validator.invalidField('courseDate', 'Please, select course')
  }

  _validateBirthday(validator) {
    let validation = DATE_VALIDATION_RULES(this.candidateBirthday);
    if (typeof validation === 'boolean') return;
    validator.invalidField('candidateBirthday', validation);
  }

  _validatePhone(validator) {
    if (!this.candidateTel) return;
    if (this.candidateTel.length < 8 || this.candidateTel.length > 13) validator.invalidField('candidateTel', 'Candidatetel should be between 8 and 13');
  }

  _validateReservation(validator) {
    if (!this.requests.length) return;
    if (this.filteredRequests.length === 1) {
      if (this.filteredRequests[0].course.city || this.filteredRequests[0].course.ID) {
        this._setRequestError(validator, 0);
      }
    }
    if (this.filteredRequests.length > 1) {
      this.filteredRequests.forEach((request, index) => {
        this._setRequestError(validator, index);
      });
    }
  }

  _setRequestError(validator, index) {
    let errors = {};
    this._setRequestFieldErrRequired('location', index, errors);
    this._setRequestCourseError(index, errors);

    let fullErrors = [];
    if (validator.fields.requests) fullErrors = validator.fields.requests
    fullErrors[index] = errors
    if (Object.keys(errors).length) validator.invalidField('requests', fullErrors)
  }

  _setRequestFieldErrRequired(field, index, errors) {
    if (!this.filteredRequests[index][field]) {
      errors[field] = 'field required'
    }
  }

  _setRequestCourseError(index, errors) {
    if (!this.filteredRequests[index].course.ID) {
      errors.courseDate = "field required"
    }
    if (!this.filteredRequests[index].course.city) {
      errors.courseLocation = "field required"
    }
  }

  getApiData() {
    let data = {
      number: this.candidateNumber,
      date_of_birth: this._getDateApiData(this.candidateBirthday),
      first_name: this.firstName,
      last_name: this.lastName,
      course_date: this._getDateApiData(this.courseDate),
      name: this.candidateName,
      email: this.candidateEmail,
      cbr_user: this.accountUsername,
      cbr_status: this.cbr_status,
      are_dates_fixed: this.are_dates_fixed,
      mijn_exam_location: this.mijn_exam_location,
      mijn_exam_datetime: this.mijn_exam_datetime,
      force_reapply_allowed_until: this.force_reapply_allowed_until,
      unsubscribe_from_suggestions: this.unsubscribe_from_suggestions,
      color: this.color,
      restore_after_exam: this.restore_after_exam,
      failed: this.failed,
      course_location: this.courseLocation,
      phone: this.candidateTel,
      product_name: this.productName,
      language: this.language,
      labels: this.labels,
      course_id: this.course.ID,
      course_type: this.course_type,
      wiki_access_days: this.wiki_access_days || 0,
      wiki_access_from_date: this._getDateApiData(this.wiki_access_from_date),
      exercises_access_expires_at: this._getDateApiData(this.dateExercisesAccessExpires) ? this._toDateObject(`${this._getDateApiData(this.dateExercisesAccessExpires)}${this.timeExercisesAccessExpires}`) : null,
      reservation_days_offset: this.reservation_days_offset || null,
      has_package: this.has_package,
      package_name: this.package_name,
      package_paid_amount: this.package_paid_amount
    };
    data.exam_reservation_requests = this._getRequestsApiData();
    return data;
  }

  _toDateObject(date) {
    if (date && date.trim().length) {
      return Dayjs(date, 'YYYY-MM-DD HH:mm').toISOString();
    }
    return null
  }

  _getDateApiData(date) {
    let _date = dateFormating(date)
    if (!_date) return null
    return _date.join('-')
  }

  _getRequestsApiData() {
    return this._getRequestsApiDataBy(this.filteredRequests)
  }

  _getRequestsApiDataBy(payload, isExchange) {
    // Todo need to refactor
    let exchange = isExchange || (this.exam.location ? true : false)
    let requests
    if (!payload || !payload.length || !payload[0].course.ID) return []
    if (exchange && !isExchange) requests = payload.filter(request => request.isExchange)
    else requests = payload.filter(request => !request.isExchange)
    requests = requests.map(request => {
      return {
        location: request.location,
        exam_date: this._getDateApiData(request.examDate),
        exam_time_from: request.timeFrom,
        exam_time_to: request.timeTo,
        course_id: request.course.ID,
        is_on_hold: request.onHold,
        comment: request.comment || '',
        [REQUEST_ARGS.EXAM_COMMENT]: request.examComment || '',
        is_exchange: exchange,
      }
    })
    return requests
  }

  _getRemoveRequestsApiDataBy(payload) {
    if (!payload || !payload.length || !payload[0].course.ID) return []
    return payload.map(request => {
      return {
        location: request.location,
        exam_date: this._getDateApiData(request.examDate),
        exam_time_from: request.timeFrom,
        exam_time_to: request.timeTo,
        course_id: request.course.ID
      }
    });
  }

  addReservationRequest() {
    this.requests.push(new ReservationRequestDTO({
      is_exchange: this.hasReservedExam ? true : false,
      added_manually: true,
    }))
  }

  addFilledReservationRequests(requests) {
    // for empty requests
    if (this.requests.length === 1 && !this.requests[0].course.ID) {
      this.requests = [...requests]
    } else {
      this.requests = uniq([...this.requests, ...requests])
    }
  }

  addReservationTo(requests) {
    requests.push(new ReservationRequestDTO({
      is_exchange: this.hasReservedExam ? true : false,
      [REQUEST_ARGS.COURSE_LOCATION]: this.courseLocation,
      added_manually: true,
    }))
  }

  removeReservationRequest(index) {
    if (this.filteredRequests.length <= 1) return
    let deletedIndex
    this.requests.forEach((request, requestIndex) => {
      if (deletedIndex >= 0) return
      let same = true
      for (let field in this.filteredRequests[index]) {
        if (this.filteredRequests[index][field] === request[field]) continue
        same = false
      }
      if (same) deletedIndex = requestIndex
    })
    this.requests.splice(deletedIndex, 1)
  }

  getSpreadsheetData(store) {
    let candidateCBRAccount = store.getters['cbrUser/getNameByID'](this.accountUsername)
    let reservedInfo = this._getExamDateData()
    let planner = this._getPlanner()
    let data = [this.candidateName, this.candidateBirthday, this.candidateNumber, this.candidateEmail, this.candidateTel, reservedInfo, candidateCBRAccount, planner]
    return data.join('\t')
  }

  _getExamDateData() {
    let reservedInfo
    if (!this.exam || !this.exam.examDate) return
    let hours = this.exam.examDate.split(' ')[1]
    let productInfo = ''
    if (this.productName === PRODUCT.BTH_VE) productInfo = 'VE'
    else if (this.isMijnReservation) productInfo = 'EE '
    if (this.productName === PRODUCT.BTH_VE && this.isMijnReservation) productInfo = 'EE+VE'
    reservedInfo = `${hours} - ${this.exam.code} ${productInfo} - ${this.exam.location}`
    return reservedInfo
  }

  _getPlanner() {
    if (this.isMijnReservation) return 'MIJNCBR'
    if (this.productName === PRODUCT.BTH) return 'Automatic Tool'
    if (this.productName === PRODUCT.BTH_VE) return 'Extratijd examen'
  }

  merge(candidate) {
    for (let field in candidate) {
      if (field === 'requests') {
        if (!candidate[field].length) return
        let requests = candidate[field].map(newRequest => {
          let sameRequest = this.requests.find(oldRequest => newRequest.ID === oldRequest.ID)
          if (!sameRequest) return newRequest
          return {
            ...newRequest,
            exam: sameRequest.exam
          }
        })
        this.requests = requests
        return
      }
      this[field] = candidate[field]
    }
  }
}
