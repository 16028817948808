import { dateFormating } from "@/util";

export class CandidatesApiData {
  constructor(item = {}) {
    this.item = item
    this._apiAttributes = {
      number: 'candidateNumber',
      date_of_birth: 'candidateBirthday',
      cbr_user: 'accountUsername',
      cbr_status: 'status',
      exam: 'exam',
      exam_reservation_requests: 'requests',
      phone: 'candidateTel',
      failed: 'failed',
      was_exchanged: 'wasExchanged',
      notes: 'notes',
      is_wait_list: 'isWaitList',
      last_error: 'lastError',
      is_in_queue: 'inQueue',
      is_in_exchange_queue: 'inExchangeQueue',
      no_dates: 'noDates',
      no_candidate_number: 'noCandidateNumber',
      acceptance_error: 'acceptanceError',
      reservation_error: 'reservationError',
      exchange_error: 'exchangeError',
      new_reserve: 'newReserve',
      new_exchange: 'newExchange',
      product_name: 'productName',
      language: 'language',
      labels: 'labels',
      is_mijn_reservation: "isMijnReservation",
      sent_email: 'sentEmails',
      payments: 'payments',
      email: 'candidateEmail',
      course_id: 'courseDate',
      course_type: 'course_type',
      are_dates_fixed: 'are_dates_fixed',
      course_configuration: 'course_configuration',
      exercises_access_expires_at: 'exercises_access_expires_at',
      exchange_count: 'exchange_count',
      color: 'color',
      will_come_to_course: 'willComeToCourse',
      unsubscribe_from_suggestions: 'unsubscribe_from_suggestions',
      restore_after_exam: 'restore_after_exam',
      needs_approval: 'needs_approval',
      has_approval: 'has_approval',
      canceled: 'canceled',
      wiki_access_days: 'wiki_access_days',
      wiki_access_from_date: 'wiki_access_from_date',
      reservation_days_offset: 'reservation_days_offset'
    };
  }

  apiData() {
    let data = {
      number: this._filledValue('candidateNumber'),
      date_of_birth: 'candidateBirthday' in this.item ? this._getDateApiData(this.item.candidateBirthday) : undefined,
      first_name: this._filledValue('firstName'),
      last_name: this._filledValue('lastName'),
      course_date: 'courseDate' in this.item ? this._getDateApiData(this.courseDate) : undefined,
      name: this._filledValue('candidateName'),
      email: this._filledValue('candidateEmail'),
      cbr_user: this._filledValue('accountUsername'),
      cbr_status: this._filledValue('cbr_status'),
      are_dates_fixed: this._filledValue('are_dates_fixed'),
      course_configuration: this._filledValue('course_configuration'),
      unsubscribe_from_suggestions: this._filledValue('unsubscribe_from_suggestions'),
      color: this._filledValue('color'),
      restore_after_exam: this._filledValue('restore_after_exam'),
      failed: this._filledValue('failed'),
      course_location: this._filledValue('courseLocation'),
      phone: this._filledValue('candidateTel'),
      product_name: this._filledValue('productName'),
      language: this._filledValue('language'),
      labels: this._filledValue('labels'),
      course_type: this._filledValue('course_type'),
      // course_id: this._filledValue(this.item.course.ID),
      wiki_access_days: 'wiki_access_days' in this.item ? (this.item.wiki_access_days || 0) : undefined,
      wiki_access_from_date: 'wiki_access_from_date' in this.item ? this._getDateApiData(this.item.wiki_access_from_date) : undefined,
      reservation_days_offset: this._filledValue('reservation_days_offset') || null,
      exercises_access_expires_at: 'exercises_access_expires_at' in this.item ?
        (this._getDateApiData(this.item.dateExercisesAccessExpires)
          ? this._toDateObject(`${this._getDateApiData(this.item.dateExercisesAccessExpires)}${this.item.timeExercisesAccessExpires}`) : null) :
        undefined,
    }
    let _changedApiData = {}
    for (let field in data) {
      if (data[field] !== undefined) _changedApiData[field] = data[field]
    }
    return _changedApiData
  }

  _filledValue(field) {
    if (field in this.item) return this.item[field]
    return undefined
  }

  _getDateApiData(date) {
    let _date = dateFormating(date)
    if (!_date) return null
    return _date.join('-')
  }
}
